.vbcn-placeholder {
  //background: rgb(255, 255, 255);
  //padding: 1rem;

  .vbcn-placeholder-headline {
    //margin-top: 0;
    //margin: 0 0 0.6em;
    //color: #333;
    //font-size: 1.8em;
  }

  .vbcn-placeholder-content {
    @include vbcn-base-style;

    padding: 20px;
  }

  .vbcn-placeholder-actions {
    text-align: right;
  }

  .vbcn-text-small {
    @include vbcn-text-small;
  }
}
