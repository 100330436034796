
@font-face {
  font-family: 'TiemposHeadline-Regular';
  src: url('../fonts/TiemposHeadline-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'TiemposHeadline-RegularItalic';
  src: url('../fonts/TiemposHeadline-RegularItalic.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'TiemposWebHeadline-LightItalic';
  src: url('../fonts/TiemposWebHeadline-LightItalic.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'TiemposHeadline-Medium';
  src: url('../fonts/TiemposHeadline-Medium.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}