$color-highlight: var(--primary);
$color-text: var(--primary); 
$color-text-dimmed: #666;
$color-error: var(--primary);

@mixin vbcn-base-style {
  background: #fff;
  color: $color-text;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

@mixin vbcn-button {
  border: solid 5px $color-highlight;
  padding: 1rem 2rem;
  transition: all 200ms ease-in-out;
  white-space: nowrap;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  background: none;
  color: $color-text;
  font-size: 1em;
  letter-spacing: 0.5px;
  padding: 0.5em 0.8em;
  cursor: pointer;
  display: inline-block;
  margin: 0 0.5em 0.5em 0;

  &:hover,
  &:active {
  }

  &:disabled {
    color: #888;
    cursor: default;
  }

  &.primary {
    background: $color-highlight;
    color: #fff;
  }
}

@mixin vbcn-text-small {
  font-size: 0.75em;
  line-height: 1.7rem;
  color: black;
}
