@import "variables";
@import "backtotop";
@import "components/vbcn/vbcn"; 
@import "admin"; 

$font-family-sans-serif: Georgia, serif;;
$font-size-base: 1.375rem;  /* p= 22px */
$h1-font-size: $font-size-base * 2.5; /* 55px */
$h2-font-size: $font-size-base * 1.5; /* 33px */
/* $h2-font-size: $font-size-base * 2.1; /* 50,6px */
$h3-font-size: $font-size-base * 1.2; /* 26,4px */
$h4-font-size: $font-size-base * 1; /* 22px */
$enable-responsive-font-sizes: true;

$body-bg: white; 
$light: white !default;
$nav-link-padding-x: 0;

$primary: #ef7720;  

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),      
    6: ($spacer * 4),      
    7: ($spacer * 5),      
    8: ($spacer * 6.25),   
    9: ($spacer * 7.5),   
    10: ($spacer * 9.375)  
  ),
  $spacers
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);

@import "../../node_modules/bootstrap/scss/bootstrap"; 

/* ----- Variables ---------------------------------------------- */

:root {
  --primary: #ef7720; 
  --blau: #48B9E9; 
}


/* ----- Allgemein ---------------------------------------------- */


body {
  padding-top: 10px;
  margin: 0;
  margin-top: 0px;

  /* hyphens - Trenungen allgemein */
  /* -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; */
}


.body-margin-top {
  margin-top: 75px;
}

.trennungen {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; 
}

::selection {
  background: var(--primary);
  color: white;
}

::-moz-selection {
  background: var(--primary);
  color: white;
}

a {
  color: var(--primary);
  transition: all 200ms ease-out;
  text-decoration: none; 
}

h1 a:hover, h1 a:focus, 
h2 a:hover, h2 a:focus, 
h6 a:hover, h6 a:focus, 
p a:hover, p a:focus, 
.submenu li a:hover, .submenu li a:focus { 
  color: var(--primary);
  text-decoration: none;
  border-bottom: 2px solid var(--primary);
}


.active {
  color: var(--primary) !important;
}

.footer a,
.titel_overlayer a {
  color: white !important;
}

.footer a:hover, .footer a:focus, .footer .active, 
.titel_overlayer a:hover, .titel_overlayer a:focus  { 
  text-decoration: none;
  border-bottom: 2px solid white;
}

.footer a.link-noborder {
  border-bottom: none;
}

.img-circle {
  border-radius: 50%;
  object-fit: cover; 
  object-position: center; 
  height: 400px;
  width: 400px;
}

.bewertung {
  height: 25px;
  width: 25px;
  background-color: var(--primary);
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.3rem;
}

#logo {
  fill: var(--primary);
  max-width: 100%;
}

.footer .logo-color {
  fill: white;
}

.footer .header-logo {
  scale: 0.7;
  transform-origin: center left;
}


hr {
  border: none;
  border-bottom: 2px dotted var(--primary);
  opacity: 1;
  background: none;
}

.btn {
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
}

.neu {
  background-color: var(--primary);
  color: white;
}

@media (max-width: 1272px) {
  .img-circle {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 997px) {
  .img-circle {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 848px) {
  .img-circle {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  
}




@media (max-width: 1024px) {
  html {
    font-size: 15px;
  }  
}


/* Smartphones und weniger */
@media (max-width: 480px) {
  html {
    font-size: 12px;
  }  

  .header-logo {
    scale: 0.5;
    transform-origin: center left;
  }

  .footer .header-logo {
    scale: 0.4;
    transform-origin: center left;
  }
   
}

@media (max-width: 435px) {
  .img-circle {
    width: 300px;
    height: 300px;
  }
}


/* ----- Typografie ---------------------------------------------- */

h1, h2, h4, h6 {
  font-family: "TiemposHeadline-Regular", sans-serif;
  font-weight: 200;
  color: var(--primary);
}

h6 {
  font-family: "TiemposHeadline-RegularItalic", sans-serif;
}

h5 {
  font-weight: 200;
  font-size: 1.375rem;
}

.text-small {
  font-size: 0.8rem;
}


/* neu */

.optimierung-h1 {
  font-family: "TiemposHeadline-Medium", sans-serif;
}

.optimierung-h2 {
  font-family: "TiemposWebHeadline-LightItalic", sans-serif;
  font-size: $font-size-base * 2.1;
  font-weight: 100;
}

.optimierung-h3 {
  font-family: prenton, sans-serif;
  font-weight: 400;
  line-height: 2.3rem;
}


body {
  font-family: prenton, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.375rem;
}

.header-bild {
  h2 {
    font-family: "TiemposHeadline-RegularItalic", sans-serif;
    font-family: prenton, sans-serif;
    font-weight: 300;
    font-style: italic;
  }
}

.header-ausgabe p {
  font-size: 0.9rem;
  line-height: 1.0rem;
}

.header-ausgabe p.header-circle {
  font-weight: 900;
  color: white;
}

.header-circle-ausgabennummer {
  font-size: 1.3rem;
}

h2.italic {
  font-family: "TiemposHeadline-RegularItalic", sans-serif;
  color: black;
}

/* ----- Navigation ---------------------------------------------- */


.navbar li {
  font-size: 1.75rem;
  color: var(--primary);
  
}

.navbar .nav-link {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.navbar-light .navbar-nav .nav-link:hover, 
.navbar-light .navbar-nav .nav-link:focus {
    color: var(--primary);
}

#suche-farbe {
  fill: #808080;
}

.suche-icon:hover {
  #suche-farbe {
    fill: var(--primary);
  }
}


.search {
  display: table;
  input {
    background: none;
    border: none;
    outline: none;
    width: 28px;
    min-width: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    line-height: 18px;
    margin: 5px 0;
    font-size: 1.75rem;
    font-family: prenton, sans-serif;
    font-weight: 300;
    -webkit-appearance: none;
    transition: all .6s ease;
    cursor: pointer;
    color: var(--primary);
    & + div {
        position: relative;
        height: 28px;
        width: 100%;
        margin: -28px 0 0 0;
        svg {
            display: block;
            position: absolute;
            height: 28px;
            width: 160px;
            right: 0;
            top: 0;
            fill: none;
            stroke: var(--primary);
            stroke-width: 1.5px;
            stroke-dashoffset: 212.908 + 59;
            stroke-dasharray: 59 212.908;
            transition: all .6s ease;
        }
    }
    &:not(:placeholder-shown),
    &:focus {
      width: 160px;
      padding: 0 4px;
      cursor: text;
      &+ div {
        svg {
            stroke-dasharray: 150 212.908;
            stroke-dashoffset: 300;
        }
      }
    }
  }
}

.dropdown-menu {
  --bs-dropdown-border-radius: 0;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: 0;
  border: 0;
  --bs-dropdown-padding-x: 1rem !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);

  .dropdown-item:hover, .dropdown-item:focus {
    background: none !important;
  }
}



@media (max-width: 991px) {
  .navbar .nav-link {
    padding-right: 0 !important;
  }

   
}

/* ----- Animation --------------------------------------------- */
/*=== Trigger  ===*/
.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*=== Optional Delays, change values here  ===*/
.one {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

/*=== FADE IN  ===*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}

/*==== FADE IN UP ===*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

body {
  animation: fadeIn 1.5s; 
}

.header-bild, .titel_aktuelle_ausgabe {
  animation: fadeInUp 0.75s; 
}

/* ----- Layout ---------------------------------------------- */

.header {
  position: relative;
}

.header-logo-wrapper {
  margin-top: 2rem;
}

.header-bild {
  min-height: 520px;

  h1,h2,h5 {
    color: white;
  }
}

.header-circle-wrapper {
  position: relative;
}

.header-ausgabe {
  position: absolute;
  top: 50px;
  right: 20px;
}

.header-circle {
  background: var(--primary);
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.header-circle-text {
  margin-left: 10px;
}


.titel_overlayer {
  padding-top: 3rem;
  padding-left: 3rem;
}

.randspalte {
  position: relative;
  top: -400px;
}

.werbung {
  border-top: 2px dotted var(--primary);
  border-bottom: 2px dotted var(--primary);
  background: none;
  padding-top: 1rem;
  margin: 0;
}

.videoeinblendung {
  position:relative;
  width:100%;
  height:0;
  padding-bottom:56.27198%;
}

.videoeinblendung iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.rezensionen-background, .submenu {
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
  padding: 2rem;
}

.magazin {
  color: var(--primary);
}

.werbeeinblendung-inhalt, .werbeeinblendung-inhalt h1, .werbeeinblendung-inhalt h2, .werbeeinblendung-inhalt h3, .werbeeinblendung-inhalt .intro {
  font-family: georgia;

  h2 {
    font-style: italic;
  }
}

.magazin-border, .werbeeinblendung {
  outline: 2px solid var(--primary);
  padding: 2.5rem;
  outline-offset: -1rem;
  margin: 0;
}

.werbeeinblendung::before { 
  content: "Werbeinformation"; 
  
  color: var(--primary);
  font-weight: bold;
} 

.werbeeinblendung-inhalt {
  border: 5px solid var(--primary);
  padding: 2rem;
  margin: 0;
}

.werbeeinblendung-headline {
  color: var(--primary) !important;
  text-decoration: underline;
  -webkit-text-decoration-style: wavy;
  -moz-text-decoration-style: wavy;
  text-decoration-style: wavy;
}

.online-artikel {
  border-bottom: 2px solid var(--primary);
}

.linie-headline {
  line-height: 0.5;
  text-align: center;
}

.linie-headline span {
  display: inline-block;
  position: relative;  
}

.linie-headline span:before,
.linie-headline span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 2px solid var(--primary);
  top: 0;
  width: 170%;
}

.linie-headline span:before {
  right: 100%;
  margin-right: 15px;
}

.linie-headline span:after {
  left: 100%;
  margin-left: 15px;
}

.footer {
  background: #4F4F4F;
  color: white;
}

.titel_aktuelle_ausgabe,
.img_border {
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.50);
}


.kalender {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
    margin-right: 20px;
    padding: 1.5rem;
    width:  76px;
    margin-bottom: 1rem;
}

.kalender:hover {
    background: var(--primary);
    color:  white !important;
    transition: all 200ms ease-out;

    h6 {
      color: white;
    }
}

.kalender a:hover {
    color:  white;
}

.ausgabeartikel {
  background-position: top !important;
  background-repeat: no-repeat;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important; 
  background-size: cover !important;
}

.ausgabeartikel-650 {
  min-height: 650px;
}

.ausgabeartikel-428 {
  min-height: 428px;
}

.ausgabeartikel-390 {
  min-height: 390px;
}

.ausgabeartikel-328 {
  min-height: 328px;
}


table {
  table-layout: fixed;
}

th.cell75,
td.cell75 {
  width: 75%;
}

th.cell50 {
  width: 50%;
}

th.cell33 {
  width: 33%;
}

th.cell25 {
  width: 25%;
}

th.cell20 {
  width: 20%;
}

th.cell3 {
  width: 3%;
} 

th.cell5,
td.cell5 { 
  width: 5%;
} 

th.cell8 {
  width: 8%;
} 

th.cell10 {
  width: 10%;
} 


@media (max-width: 991px) {
  .randspalte {
    top: 0px;
  }

  .linie-headline span:before,
  .linie-headline span:after {
    width: 100%;
  }

  .titel_aktuelle_ausgabe {
    max-width: 50%;
  }
}

@media (max-width: 529px) {
  .linie-headline span:before,
  .linie-headline span:after {
    width: 80%;
  }
}

/* Smartphones und weniger */
@media (max-width: 480px) {
  .titel_overlayer {
    padding-bottom: 1rem;
    padding-left: 2rem;
    position: absolute;
    max-width: 80vw;
    bottom: 0;
  }

  .header-bild {
    min-height: 450px;
  }

  .header-logo-wrapper {
    margin-top: 0;
  }

  .titel_aktuelle_ausgabe {
    max-width: 80%;
  }

  .cover-img {
    max-width: 65%;
  }
}

@media (max-width: 350px) {
  .linie-headline span:before,
  .linie-headline span:after {
    width: 50%;
  }
}



/* ----- aus alter Seite ---------------------------------------------- */

#skyscraper {
    float:right;
    height: 600px; 
    width: 200px; 
}

.fahne {
    position: fixed;
    width: 301px;
    height: 30px;
    top: 500px;
    left: -136px;
    transform: rotate(-90deg); 
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
}

.abo_fahne{
  transition: all 200ms ease-out;
  float: left;
  background-color: var(--primary); 
  width: 150px;
  height: 30px;
}

.abo_fahne p {
    text-align: center;
    color: #fff; 
    margin: 0 0 10px 0;
    text-decoration: none;
}

.newsletter_fahne {
  transition: all 200ms ease-out;
  margin-left: 1px;
  float: left;
  background-color: var(--primary); 
  width: 150px;
  height: 30px;
}

.newsletter_fahne:hover,
.abo_fahne:hover {
  height: 40px;
}

.newsletter_fahne p {
    text-align: center;
    line-height: 19px; 
    color: #fff; 
    margin: 5px 0 10px 0;
    text-decoration: none;
}

.newsletter_fahne a {
    text-decoration: none;
}

/* slideshow */
.cycle-slideshow { width: 100%; height: auto; margin-top: 40px; margin-bottom: 10px; }
.cycle-slideshow img { opacity: 0; filter:alpha(opacity=0); }
.cycle-pager { text-align: center; width: 100%; z-index: 500; position: absolute; bottom: 1px; }
.cycle-pager span { font-family: arial; font-size: 50px; display: inline-block; color: #ddd; cursor: pointer; }
.cycle-pager span.cycle-pager-active { color: var(--primary);}
.cycle-pager > * { cursor: pointer;}


@media (max-width: 1500px) {
  .fahne {
    visibility:hidden;
  }
}